<div class="w-full bg-white
            flex flex-col p-[0.938rem]
            px-[1.438rem] md:px-[6.563rem]
            pt-[2.313rem] md:pt-[5rem]">
    <div class="w-full max-w-header
                flex flex-col
                mx-auto
                gap-2 md:text-center">
        <div class="w-full text-2xl
                    leading-[2.25rem]
                    md:text-[2.375rem]
                    md:leading-[3.563rem]
                    text-darker font-semibold">
            Kies een schoonmaakpakket, bekijk direct de offerte en boek ons online!
        </div>

        <div class="h-1 w-32
                    bg-divider md:mx-auto
                    my-[0.9rem] md:my-[1rem]"></div>

        <div class="w-full text-gray">
            Kies hier een pakket. Achter elke keuze vind je onze werkzaamheden.
            Of kies de schoonmaak op maat, dan mag je het zelf zeggen.
            Je ziet eerst de prijs voordat je gaat bestellen.
        </div>
    </div>

    <div class="w-full max-w-container
                mx-auto my-[1.625rem]
                flex flex-wrap
                justify-center gap-8">
        <div class="w-full max-w-[21.813rem]
                    px-0 md:px-3.5
                    py-[1.563rem]">
            <sw-card [item]="item1"
                     [btn1]="true"
                     [btn2]="true"
                     (btn1Click)="onBtn1('gs')"
                     (btn2Click)="onBtn2('/schoonmaakdiensten')"
            ></sw-card>
        </div>

        <div class="w-full max-w-[21.813rem]
                    px-0 md:px-3.5
                    py-[1.563rem]">
            <sw-card [item]="item2"
                     [btn1]="true"
                     [btn2]="true"
                     (btn1Click)="onBtn1('ms')"
                     (btn2Click)="onBtn2('/schoonmaakdiensten/schoonmaak-per-uur')"
            ></sw-card>
        </div>
    </div>

  <div class="w-full flex
              justify-center items-center
              md:my-[3.125rem]">
      <button type="button"
              [routerLink]="['/klantenservice']"
              class="min-h-[3.813rem] text-lg
                     px-14 py-2
                     ripple disabled:cursor-wait
                     font-semibold text-white
                     rounded-full bg-primary">
          Ik heb nog een vraag
      </button>
  </div>
</div>
