<div *ngIf="!isPartner"
     class="flex md:hidden
            w-full bg-primary
            py-6 px-[0.938rem]
            mx-auto text-inherit
            text-lg font-semibold">
  {{ title }}
</div>

<div *ngIf="!isPartner"
     class="w-full md:px-[0.938rem]">
  <div (click)="focusChildInput()"
       class="h-fit w-full
              hidden md:flex
              max-w-container mx-auto
              justify-between cursor-text
              flex-col sm:flex-row
              items-center gap-4
              rounded-[2.25rem]
              bg-secondary shadow group
              shadow-light-gray
              py-8 px-[0.938rem]"
       [class.bg-blur]="customBackground"
       [class.blur-shadow]="customBackground">

    <div class="flex w-full text-2xl
                  text-inherit font-semibold">
          <span>
              {{ title }}
          </span>
    </div>

    <form [formGroup]="zipCodeForm"
          #form=ngForm
          (ngSubmit)="onStart(form)"
          class="h-12 w-min-fit
                 w-full relative
                 flex flex-nowrap
                 rounded-full shadow
                 ring-2 ring-transparent
                 hover:ring-primary
                 focus:ring-primary
                 group-hover:ring-primary
                 focus-within:ring-primary"
          [ngClass]="{ '!ring-[#ef4444]': zipCodeForm.get('zipCode')?.invalid &&
                       (zipCodeForm.get('zipCode')?.touched || form?.submitted) }">
      <input #zipCodeEl
             class="bg-white appearance-none
                    border-none outline-none
                    w-full rounded-l-full
                    p-[0.938rem] text-darker
                    placeholder:text-placeholder"
             formControlName="zipCode"
             [placeholder]="placeholder"/>

      <button type="submit"
              class="flex items-center
                     w-fit h-full
                     border border-primary
                     outline-none appearance-none
                     px-9 shadow-primary
                     whitespace-nowrap
                     text-center text-white
                     cursor-pointer
                     rounded-r-full bg-primary">
        {{ buttonTitle }}
      </button>
    </form>
  </div>

  <div (click)="focusChildInput()"
       class="h-fit w-full
              flex md:hidden group
              max-w-container mx-auto
              justify-between cursor-text
              flex-col sm:flex-row
              items-center gap-4
              bg-transparent rounded-[2.25rem]
              py-8 px-[0.938rem]">
    <form [formGroup]="zipCodeForm"
          #form=ngForm
          (ngSubmit)="onStart(form)"
          class="h-12 w-min-fit
                 w-full relative
                 flex flex-nowrap
                 rounded-full shadow
                 ring-2 ring-transparent
                 hover:ring-primary
                 focus:ring-primary
                 group-hover:ring-primary
                 focus-within:ring-primary"
          [ngClass]="{ '!ring-[#ef4444]': zipCodeForm.get('zipCode')?.invalid && (zipCodeForm.get('zipCode')?.touched || form?.submitted) }">
      <input #zipCodeEl class="bg-white appearance-none
                          border-none outline-none
                          w-full rounded-l-full
                          p-[0.938rem] text-darker
                          placeholder:text-placeholder"
             formControlName="zipCode"
             [placeholder]="placeholder"/>

      <button type="submit"
              class="flex items-center
                     w-fit h-full
                     border border-primary
                     outline-none appearance-none
                     px-9 shadow-primary
                     whitespace-nowrap
                     text-center text-white
                     cursor-pointer
                     rounded-r-full bg-primary">
        {{ buttonTitle }}
      </button>
    </form>
  </div>
</div>
