<div *ngIf="page$ | async as page"
     class="w-full h-fit
            mt-16 lg:mt-32
            overflow-y-auto overflow-x-hidden">
  <div class="w-full flex flex-col">
    <!--  Top Section  -->
    <div class="w-full bg-white
                    border-b-2 border-divider
                    p-[0.938rem]  hidden md:flex">
      <div class="w-full max-w-container
                        mx-auto flex
                        flex-row flex-nowrap
                        justify-between items-center">
        <div class="text-lg text-darker font-semibold">
          {{ page?.title | capitalizeFirst }}
        </div>

        <div class="hidden md:flex
                            gap-x-1.5 justify-end">
                  <span [routerLink]="['/']"
                        class="cursor-pointer hover:text-primary text-secondary-gray">
                    Home /
                  </span>

          <div class="text-darker capitalize">{{ page?.path | convertBreadCrumb }}</div>
        </div>
      </div>
    </div>
    <!--  Top Section  -->

    <!--  Body  -->
    <div class="w-full bg-white
                    p-[0.938rem] pb-9">
      <div class="w-full max-w-container
                        h-fit min-h-[40vh]
                        mx-auto flex
                        flex-col gap-8">
                <div class="w-full max-w-header
                                    flex flex-col gap-2
                                    md:mx-auto mt-[1.5rem]
                                    md:mt-[5.5rem]
                                    mb-0 md:mb-[1.5rem]
                                    md:text-center">
                  <h1 class="w-full text-2xl
                                        leading-[2.25rem]
                                        md:text-[2.375rem]
                                        md:leading-[3.563rem]
                                        text-darker font-semibold">
                    {{ page?.title | capitalizeFirst }}
                  </h1>

                  <div class="h-1 w-32
                                        bg-divider md:mx-auto
                                        my-[0.9rem] md:my-[1rem]"></div>
                </div>

        <div
          class="gap-y-7 bg-secondary rounded-3xl p-[0.938rem] md:p-[2.625rem] mt-[1.5rem]  md:mt-[2.5rem] cms-page"
          [innerHTML]="getSafeHtml(page.content)"></div>
      </div>
    </div>
    <div class="w-full bg-primary
    p-[0.938rem]                    text-white md:py-12                   ">
      <sw-zip-code [customBackground]="true"></sw-zip-code>
    </div>
    <sw-ratings></sw-ratings>

    <div class="w-full max-w-header
                flex flex-col
                gap-2 p-[0.938rem]
                md:mx-auto my-[1.5rem]
                mb-0 md:mb-[1.5rem]
                md:text-center">
      <div class="w-full text-2xl
                    leading-[2.25rem]
                    md:text-[2.375rem]
                    md:leading-[3.563rem]
                    text-darker font-semibold">
        Schoonmaakwoede aan het werk
      </div>

      <div class="h-1 w-32
                    bg-divider md:mx-auto
                    my-[0.9rem] md:my-[1rem]"></div>
    </div>
    <sw-video></sw-video>
    <div class="w-full bg-white py-[3.125rem]">
      <sw-zip-code [customBackground]="false"></sw-zip-code>
    </div>
    <!--  Body  -->
    <sw-section-three></sw-section-three>
  </div>

</div>
