import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ConvertISOstringPipe} from "./convertISOstring.pipe";

@NgModule({
    declarations: [
        ConvertISOstringPipe
    ],
    imports: [
        CommonModule,
    ],
    exports: [
      ConvertISOstringPipe
    ]
})
export class ConvertIsoModule {
}
