import {Injectable} from "@angular/core";
import {collection, collectionSnapshots, Firestore, limit, query, where} from "@angular/fire/firestore";
import {catchError, Observable, take, throwError} from "rxjs";
import {PageInterface} from "../../shared/interfaces/page.interface";
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot} from "@angular/router";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: "root"
})
export class CmsService {
  constructor(private firestore: Firestore, private router: Router) {
  }


  getCMSEntryByUrl(url: string): Observable<PageInterface> {


    const inputUrl = url.substring(1);


    const colRef = collection(this.firestore, "pages");
    const q = query(colRef, where("path", "==", inputUrl), limit(1));
    const querySnapshot = collectionSnapshots(q);

    return querySnapshot.pipe(
      take(1),
      map(collectionDocs => {
        return collectionDocs.map(doc => doc.data() as PageInterface)
      }),
      map(pages => {
        if (!pages.length) {
          throw new Error(`No match found for ${url}`);
        }
        return pages[0] as PageInterface;
      }),
      catchError(err => {
        // Redirect to home on any error
        this.router.navigate([""]);
        return throwError(() => new Error(err.message || "An error occurred"));
      })
    );
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const fullPath = state.url;
    return this.getCMSEntryByUrl(fullPath);
  }
}
