import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import {CmsPageComponent} from "./cms-page.component";
import {SharedModule} from "../../shared.module";
import {HomeModule} from "../../../modules/home/home.module";
import {CapitalizeFirstCharacterModule} from "../../pipes/capitalizeFirstCharacter.module";
import {ConvertBreadCrumbModule} from "../../pipes/convertBreadCrumb.module";


@NgModule({
  declarations: [
    CmsPageComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    HomeModule,
    CapitalizeFirstCharacterModule,
    ConvertBreadCrumbModule,
  ]
})
export class CmsPageModule { }
