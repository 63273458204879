import {Injectable} from "@angular/core";
import {collection, collectionSnapshots, doc, docSnapshots, Firestore, limit, orderBy, query, where} from "@angular/fire/firestore";
import {catchError, filter, Observable} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {ContactBodyInterface} from "../../shared/interfaces/contact-body.interface";
import {map} from "rxjs/operators";
import {GoogleReview, GoogleReviewList} from "../../shared/interfaces/google-review.interface";

@Injectable({
  providedIn: "root"
})
export class FirestoreService {
  constructor(private firestore: Firestore,
              private http: HttpClient) {
  }

  sendMail(body: ContactBodyInterface): Observable<string> {
    const url = `${environment.apiUrl}/sendMail`;
    return this.http.post<string>(url, body);
  }


  getReviews(ltf: number): Observable<GoogleReview[]> {
    const colRef = collection(this.firestore, "googleReviews");
    const q = query(colRef,  orderBy("lastUpdatedAt", "desc"),   limit(ltf));


    return collectionSnapshots(q)
      .pipe(
        map(collection => {
          return collection
            .map(doc => doc.data() as GoogleReview)
            .filter((item) => item.starRatingNumber > 2);
        }),
        catchError(err => {
          return Promise.reject(err);
        }));
  }

  getLatestReviews(): Observable<GoogleReview[]> {
    const colRef = collection(this.firestore, "googleReviews");
    const q = query(colRef,  orderBy("lastUpdatedAt", "desc"), limit(20));

    return collectionSnapshots(q)
      .pipe(
        map(collection => {
          return collection
            .map(doc => doc.data() as GoogleReview)
            .filter(item => item.starRatingNumber > 2)
            .filter(review => review.commentNL && review.commentNL.length > 0)
        }),
        catchError(err => {
          return Promise.reject(err);
        }));
  }

  getStats(): Observable<Partial<GoogleReviewList>> {
    const docRef = doc(this.firestore, "stats", "googleReviewRatings");
    return docSnapshots(docRef).pipe(map((doc) => doc.data() as Partial<GoogleReviewList>));
  }
}
