import {Component, EventEmitter, Inject, Output, PLATFORM_ID} from "@angular/core";
import {PackageInterface} from "../../../shared/interfaces/package.interface";
import {SectionItemsInterface} from "../../../shared/interfaces/section-items.interface";
import {environment} from "../../../../environments/environment";
import {Router} from "@angular/router";
import {DOCUMENT, isPlatformBrowser} from "@angular/common";

@Component({
  selector: "sw-section-one",
  templateUrl: "./section-one.component.html",

})
export class SectionOneComponent {
  @Output() openLink: EventEmitter<PackageInterface["packageId"]> = new EventEmitter<PackageInterface["packageId"]>();

  item1: SectionItemsInterface = {
    header: 'Pakketten',
    icon: "reanovation",
    text: "In één keer je hele huis weer fris. Eén, twee of vier keer per jaar of gewoon elke maand. Dan heb je geen werkster meer nodig!"
  }

  item2: SectionItemsInterface = {
    header: "Schoonmaak per uur",
    icon: "customize",
    text: "Het schoonmaakpakket dat je zelf samenstelt. Wij leveren het schoonmaakteam, jij stuurt ze aan. Maatwerk dus!"
  }

  constructor(private router: Router,
              @Inject(PLATFORM_ID) private platformId: Object,
              @Inject(DOCUMENT) private document: Document,) {
  }

  onBtn1(path: string): void {
    if(isPlatformBrowser(this.platformId)) {
      const url = `${environment.redirectUrl}location?packageId=${path}`;
      this.document.location.href = url;
    }
  }

  onBtn2(path: string): void {
    this.router.navigate([path]);
  }
}
