import {Component, Input} from '@angular/core';
import {SectionItemsInterface} from "../../../interfaces/section-items.interface";

@Component({
  selector: 'sw-cleaning-card',
  templateUrl: './cleaning-card.component.html',

})
export class CleaningCardComponent {
  @Input() item?: SectionItemsInterface;
  @Input() number?: number;
}
