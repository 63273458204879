<div class="w-full lg:h-36
            py-[0.938rem] lg:py-7 z-40
            px-[0.938rem] bg-transparent">

  <div class="w-full max-w-container
              h-10 lg:h-[4.313rem]
              mx-auto flex
              rounded-full flex-nowrap
              items-center justify-between
              lg:justify-start
              transition-all duration-300"
       [class.bg-blur]="isScrolled">
    <img [routerLink]="['/']"
         class="h-[100px] p-0.5
                hidden lg:flex
                transition-all duration-300
                cursor-pointer"
         alt="logo"
         [class.scale-50]="isScrolled"
         src="assets/logo.png"/>

    <div class="w-full max-w-container
                h-10 lg:h-[4.313rem]
                px-[0.938rem] mx-auto
                flex flex-nowrap gap-8
                justify-between lg:justify-start
                items-center text-sm
                text-darker bg-white
                blur-shadow
                rounded-full shadow">
      <img [routerLink]="['/']"
           class="h-10 p-0.5
                  lg:hidden cursor-pointer"
           alt="logo"
           src="assets/logo.png"/>

      <div class="hidden lg:flex
                  min-w-fit pl-3
                  flex-nowrap justify-start
                  items-center gap-8
                  whitespace-nowrap text-center">
        <a *ngFor="let page of pages"
           href="javascript:;"
           [routerLink]="[page?.routeLink]"
           [routerLinkActiveOptions]="{exact: page?.routeLink !== '/schoonmaakdiensten'}"
           routerLinkActive="text-primary"
           class="w-1/6 min-w-fit cursor-pointer
                  uppercase hover:text-primary">
          {{ page?.label }}
        </a>

        <a [href]="redirectUrl"
           target="_blank"
           class="w-1/6 min-w-fit cursor-pointer
                  uppercase hover:text-primary">
          Login
        </a>
      </div>

      <div class="hidden lg:flex grow
                  flex-nowrap items-center
                  justify-end gap-8">
        <div class="flex items-center gap-2" (click)="switchLocale()">
          <img src="assets/icons/letter.svg"
               alt="letter"/>

          <span class="hidden xl:flex
                       cursor-pointer uppercase text-dark">
                  English
              </span>


          <span class="xl:hidden
                       cursor-pointer uppercase text-dark">
                  EN
              </span>
        </div>

        <button type="button"
                (click)="onBtn1()"
                class="h-9 px-10 py-2
                       bg-primary rounded-full
                       text-sm font-semibold
                       hover:ripple
                       text-white ripple">
          Bestel nu
        </button>
      </div>

      <!-- **Menu** -->
      <img (click)="showHeader = !showHeader"
           class="flex lg:hidden cursor-pointer"
           src="assets/icons/menu.svg"
           alt="menu"/>
    </div>
  </div>
</div>

<!-- **Menu ** -->
<div *ngIf="showHeader"
     [@enterAnimation]
     [@leaveAnimation]
     class="w-full h-fit
            lg:hidden
            absolute left-0
            top-16 z-50
            py-1 px-[0.938rem]">
  <div class="w-full h-fit
                rounded-[2.25rem] shadow
                bg-white text-darker
                blur-shadow
                flex flex-col p-6
                items-center gap-6">
    <a *ngFor="let page of pages"
       href="javascript:;"
       [routerLink]="[page?.routeLink]"
       routerLinkActive="text-primary"
       [routerLinkActiveOptions]="{exact: true}"
       (click)="showHeader = !showHeader"
       class="w-full cursor-pointer text-[0.875rem]
                  uppercase hover:text-primary">
      {{ page?.label }}
    </a>

    <div class="w-full">
      <button type="button"
              (click)="onBtn1()"
              class="w-fit h-[1.75rem]
                     px-6 py-2
                     flex items-center
                     bg-primary rounded-full
                     hover:ripple
                     text-sm font-semibold
                     text-white ripple">
        Bestel
      </button>
    </div>

    <div class="w-full h-8
                flex gap-4
                flex-nowrap justify-between">
      <div class="flex items-center gap-2" (click)="switchLocale()">
        <img src="assets/icons/letter.svg"
             alt="letter"/>

        <span class="capitalize text-[1.063rem]
                       cursor-pointer text-darker">
            EN
        </span>
      </div>
    </div>
  </div>
</div>
