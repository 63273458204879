<div *ngIf="ratings$ | async as ratings"
     class="w-full bg-white
            flex flex-col px-[0.938rem]
            pt-[3.125rem]">
  <div class="w-full max-w-header mx-auto
                flex flex-col p-[0.938rem]">
    <div class="w-full text-2xl
                    leading-[2.25rem]
                    md:text-[2.375rem]
                    md:leading-[3.563rem]
                    md:text-center
                    text-darker font-semibold">
      Onze klanten over Schoonmaakwoede
    </div>

    <div class="h-1 w-32
                bg-divider md:mx-auto
                my-[0.9rem] md:my-[1rem]"></div>
  </div>

  <div class="w-full bg-white
                max-w-container mx-auto
                flex flex-col">
    <div class="md:hidden relative h-[22.875rem]">
      <section class="carousel" aria-label="Gallery">
        <ol class="carousel__viewport min-h-[20.875rem]">
          <li *ngFor="let rating of ratings.slice(0,3); let i = index"
              id="carousel__slide{{i}}"
              class="carousel__slide mx-2.5">
            <div class="carousel__snapper">
              <div class="w-full min-w-[20.5rem]
                          max-w-[26.375rem] shadow
                          h-[15.875rem] mx-auto
                          bg-secondary relative
                          flex flex-col
                          items-center justify-center
                          shadow-light-gray
                          transition-all mt-10
                          rounded-[1.563rem] p-6">
                <div class="h-fit w-fit
                            absolute -top-8
                            star-left-position p-4
                            text-center shadow
                            bg-secondary rounded-[1.125rem]">
                  <div class="stars" [style]="'--rating: ' +  rating.starRatingNumber">
                  </div>
                </div>

                <div class="text-lg text-darker
                            text-ellipsis overflow-hidden
                            mt-[3.125rem]
                            text-center font-semibold capitalize">
                  {{ rating?.reviewer?.displayName }}
                </div>


                <div class="w-full h-[6.6rem]
                            pt-4 mb-4 px-[0.938rem]
                            text-gray text-center">
                  <div class="rating-text" *ngIf="rating?.commentNL">
                    {{ rating.commentNL.length > 0 ? rating?.commentNL : 'Enkel ster beoordeling gegeven' }}
                  </div>

                </div>

              </div>
            </div>
          </li>
        </ol>
      </section>
    </div>

    <div class="w-full hidden my-[4rem]
                md:flex flex-col
                md:flex-row justify-around
                md:flex-wrap
                gap-x-[1.125rem] gap-y-8">
      <div *ngFor="let rating of ratings?.slice(0,3)"
           class="h-full w-[25.125rem]
                  md:w-[27.625rem]">
        <div class="w-full h-full mx-auto">
          <div class="w-full h-[15.875rem]
                      bg-secondary relative
                      flex flex-col mt-10
                      items-center justify-center
                      shadow-md shadow-light-gray
                      rounded-[2.25rem] p-6 pt-16">
            <div class="h-fit w-fit
                        absolute -top-8
                        star-left-position p-4
                        text-center shadow
                        bg-secondary rounded-[1.563rem]">
              <div class="stars" [style]="'--rating: ' +  rating.starRatingNumber">
              </div>
            </div>

            <div class="text-lg text-darker
                        text-ellipsis overflow-hidden
                        text-center font-semibold">
              {{ rating?.reviewer?.displayName }}
            </div>


            <div class="w-full h-[6.6rem]
                              pt-4 mb-4 px-[0.938rem]
                              text-gray text-center">
              <div class="rating-text" *ngIf="rating?.commentNL">
                {{ rating.commentNL.length > 0 ? rating?.commentNL : 'Enkel cijfer beoordeling gegeven' }}
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>

    <div [routerLink]="['/referenties']"
         class="w-full flex
                mb-[3.125rem] md:my-[3.125rem]
                justify-center items-center">
      <button type="button"
              class="min-h-[3.813rem] px-14
                     md:px-20 py-2 text-lg
                     ripple ripple-white
                     disabled:cursor-wait
                     font-semibold text-primary
                     border border-primary
                     rounded-full bg-secondary">
        Lees meer referenties
      </button>
    </div>
  </div>
</div>
