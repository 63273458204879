<form [formGroup]="contactForm"
      #form=ngForm
      (ngSubmit)="onSubmit(form)"
      class="min-h-fit w-full
             flex flex-wrap">
  <div class="w-full flex
              md:h-[6.875rem]
              flex-col md:flex-row">
    <div class="w-full md:w-1/2 h-full
                md:mr-2 lg:mr-4 mb-4">
      <label class="block text-darker
                    font-semibold mb-2"
             for="firstName">
        Voornaam
      </label>
      <input class="bg-white shadow
                    appearance-none
                    border border-input-gray
                    rounded-lg w-full
                    p-[0.938rem] text-darker
                    placeholder:text-placeholder
                    focus:outline-none
                    ring-2 ring-transparent
                    hover:ring-primary
                    focus-within:ring-primary
                    focus:shadow-outline"
             id="firstName"
             formControlName="firstName"
             type="text"
             placeholder="Je Voornaam"
             [ngClass]="{ 'disabled:opacity-50': !getFormControl('firstName')?.value,
                          '!ring-[#ef4444]': getFormControl('firstName')?.invalid && (getFormControl('firstName')?.touched || form.submitted)}">
      <div *ngIf="getFormControl('firstName')?.invalid && (getFormControl('firstName')?.touched || form.submitted)"
           class="w-full text-xs mt-1
                text-[#ef4444] pl-3.5">
        <div *ngIf="getFormControl('firstName')?.errors?.['required']">
          <span class="capitalize">Voornaam</span> is een verplicht veld.
        </div>
      </div>

    </div>

    <div class="w-full md:w-1/2 h-full
                md:ml-2 lg:ml-4 mb-4">
      <label class="block text-darker
                    font-semibold mb-2"
             for="lastName">
        Achternaam
      </label>
      <input class="bg-white shadow
                    appearance-none
                    border border-input-gray
                    rounded-lg w-full
                    p-[0.938rem] text-darker
                    placeholder:text-placeholder
                    focus:outline-none
                    ring-2 ring-transparent
                    hover:ring-primary
                    focus-within:ring-primary
                    focus:shadow-outline"
             id="lastName"
             formControlName="lastName"
             type="text"
             placeholder="Je Achternaam"
             [ngClass]="{ 'disabled:opacity-50': !getFormControl('lastName')?.value,
                          '!ring-[#ef4444]': getFormControl('lastName')?.invalid && (getFormControl('lastName')?.touched || form.submitted)}">
      <div *ngIf="getFormControl('lastName')?.invalid && (getFormControl('lastName')?.touched || form.submitted)"
           class="w-full text-xs mt-1
                text-[#ef4444] pl-3.5">
        <div *ngIf="getFormControl('lastName')?.errors?.['required']">
          <span class="capitalize">Achternaam</span> is een verplicht veld.
        </div>
      </div>

    </div>
  </div>

  <div class="w-full flex
              md:h-[6.875rem]
              flex-col md:flex-row">
    <div class="w-full md:w-1/2 h-full
                md:mr-2 lg:mr-4 mb-4">
      <label class="block text-darker
                    font-semibold mb-2"
             for="email">
        E-mailadres
      </label>
      <input class="bg-white shadow
                    appearance-none
                    border border-input-gray
                    rounded-lg w-full
                    p-[0.938rem] text-darker
                    placeholder:text-placeholder
                    ring-2 ring-transparent
                    hover:ring-primary
                    focus-within:ring-primary
                    focus:outline-none
                    focus:shadow-outline"
             id="email"
             formControlName="email"
             type="email"
             placeholder="Je E-mailadres"
             [ngClass]="{ 'disabled:opacity-50': !getFormControl('email')?.value,
                          '!ring-[#ef4444]': getFormControl('email')?.invalid && (getFormControl('email')?.touched || form.submitted)}">
      <div *ngIf="getFormControl('email')?.invalid && (getFormControl('email')?.touched || form.submitted)"
           class="w-full text-xs mt-1
                text-[#ef4444] pl-3.5">
        <div *ngIf="getFormControl('email')?.errors?.['required']">
          <span class="capitalize">E-mail adres</span> is een verplicht veld.
        </div>

        <div *ngIf="getFormControl('email')?.errors?.['email']">
          Graag een geldig email adres in vullen
        </div>
      </div>
    </div>


    <div class="w-full md:w-1/2 h-full
                md:ml-2 lg:ml-4 mb-4">
      <label class="block text-darker
                    font-semibold mb-2"
             for="phoneNumber">
        Telefoonnummer
      </label>
      <input class="bg-white shadow
                    appearance-none
                    border border-input-gray
                    rounded-lg w-full
                    p-[0.938rem] text-darker
                    placeholder:text-placeholder
                    ring-2 ring-transparent
                    hover:ring-primary
                    focus-within:ring-primary
                    focus:outline-none
                    focus:shadow-outline"
             formControlName="phoneNumber"
             id="phoneNumber"
             type="tel"
             placeholder="Je Telefoonnummer"
             [ngClass]="{ 'disabled:opacity-50': !getFormControl('phoneNumber')?.value,
                          '!ring-[#ef4444]': getFormControl('phoneNumber')?.invalid && (getFormControl('phoneNumber')?.touched || form.submitted)}">
      <div *ngIf="getFormControl('phoneNumber')?.invalid && (getFormControl('phoneNumber')?.touched || form.submitted)"
           class="w-full text-xs mt-1
                text-[#ef4444] pl-3.5">
        <div *ngIf="getFormControl('phoneNumber')?.errors?.['pattern']"
             class="w-full text-[#ef4444] pl-3.5">
            <span>
                Nummer invullen als +3164347244 of 064347244
            </span>
        </div>

        <div *ngIf="getFormControl('phoneNumber')?.errors?.['required']">
          <span class="capitalize">Telefoonnummer</span> is een verplicht veld.
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isBusiness"
       class="w-full flex
              md:h-[6.875rem]
              flex-col md:flex-row">
    <div class="w-full mb-4">
      <label class="block text-darker
                    font-semibold mb-2"
             for="business">
        Bedrijfsnaam
      </label>
      <input class="bg-white shadow
                    appearance-none
                    border border-input-gray
                    rounded-lg w-full
                    p-[0.938rem] text-darker
                    placeholder:text-placeholder
                    ring-2 ring-transparent
                    hover:ring-primary
                    focus-within:ring-primary
                    focus:outline-none
                    focus:shadow-outline"
             id="business"
             formControlName="business"
             type="text"
             placeholder="Je bedrijfsnaam"
             [ngClass]="{ 'disabled:opacity-50': !getFormControl('business')?.value,
                          '!ring-[#ef4444]': getFormControl('business')?.invalid && (getFormControl('business')?.touched || form.submitted)}">
      <div *ngIf="getFormControl('business')?.invalid && (getFormControl('business')?.touched || form.submitted)"
           class="w-full text-xs mt-1
                text-[#ef4444] pl-3.5">
        <div *ngIf="getFormControl('business')?.errors?.['required']">
          <span class="capitalize">Bedrijfsnaam</span> is een verplicht veld.
        </div>
      </div>
    </div>
  </div>


  <div class="w-full flex h-fit">
    <div class="w-full min-h-[12.9rem]">
      <label class="block text-darker
                      font-semibold mb-2"
             for="remark">
        Opmerking
      </label>
      <textarea class="w-full rounded-lg
                         text-darker shadow
                         placeholder:text-placeholder
                         ring-2 ring-transparent
                         hover:ring-primary
                         focus-within:ring-primary
                         border border-input-gray"
                id="remark"
                formControlName="remark"
                [value]="''"
                placeholder="Laat hier je bericht achter"
                rows="5"
                [ngClass]="{ 'disabled:opacity-50': !getFormControl('remark')?.value,
                             '!ring-[#ef4444]': getFormControl('remark')?.invalid && (getFormControl('remark')?.touched || form.submitted)}">
        </textarea>
      <div *ngIf="getFormControl('remark')?.invalid && (getFormControl('remark')?.touched || form.submitted)"
           class="w-full text-xs mt-1
                text-[#ef4444] pl-3.5">
        <div *ngIf="getFormControl('remark')?.errors?.['required']">
          <span class="capitalize">Opmerking</span> is een verplicht veld.
        </div>
      </div>
    </div>
  </div>

  <div class="w-full flex h-fit">
    <div class="w-full flex
                  flex-col items-center
                  gap-4 justify-center">
      <div class="w-full text-center text-lg">
        <div *ngIf="error"
             class="text-red-500">
          Er is een fout opgetreden
        </div>

        <div *ngIf="success"
             class="text-green-500">
          E-mail succesvol verzonden
        </div>
      </div>

      <button type="submit"
              [disabled]="loading"
              class="w-full h-16
                     p-[0.938rem] bg-primary
                     ripple cursor-pointer
                     disabled:cursor-wait
                     text-white rounded-full">
        Verzenden
      </button>

      <div class="text-gray text-sm">
        We nemen binnen één werkdag contact op
      </div>

    </div>
  </div>
</form>
