import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {CapitalizeFirstCharacterPipe} from "./capitalizeFirstCharacter.pipe";

@NgModule({
    declarations: [
        CapitalizeFirstCharacterPipe
    ],
    imports: [
        CommonModule,
    ],
    exports: [
        CapitalizeFirstCharacterPipe
    ]
})
export class CapitalizeFirstCharacterModule {
}
