import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private scrollSubject = new Subject<void>();
  private _showModalBehaviour: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  showModal$ = this._showModalBehaviour.asObservable();
  scroll$ = this.scrollSubject.asObservable();


  fireScrollEvent() {
    this.scrollSubject.next();
  }

  closeModal() {
    this._showModalBehaviour.next(false)
  }

  openModal() {
    this._showModalBehaviour.next(true)
  }
}
