import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild
} from "@angular/core";
import {NavigationEnd, Router} from "@angular/router";
import {filter, Observable, Subject, takeUntil} from "rxjs";
import {DOCUMENT, isPlatformBrowser} from "@angular/common";
import {environment} from "../environments/environment";
import {SharedService} from "./core/services/shared.service";
import {ScrollDispatcher} from "@angular/cdk/overlay";
import {Meta, Title} from "@angular/platform-browser";
import {AnalyticsService} from "./core/services/analytics.service";


@Component({
  selector: "sw-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, OnDestroy {
  private onDestroy: Subject<void> = new Subject<void>();
  @ViewChild("swApp") swApp?: ElementRef;
  showModal$: Observable<boolean>;
  isScrolled: boolean = false;

  constructor(
    @Inject(DOCUMENT) private doc: Document,
    @Inject(PLATFORM_ID) private platform: Object,
    private sharedService: SharedService,
    private cdRef: ChangeDetectorRef,
    private scrollDispatcher: ScrollDispatcher,
    private analyticsService: AnalyticsService,
    private title: Title,
    private meta: Meta,
    private router: Router) {
    this.showModal$ = sharedService.showModal$;
    if (isPlatformBrowser(this.platform)) {
      console.log(`${environment.name} release: `, environment.releaseVersion);

      this.scrollDispatcher
        .scrolled()
        .pipe(takeUntil(this.onDestroy))
        .subscribe((scrollable: any) => {
          // Handle scroll event here
          const container = scrollable.getElementRef().nativeElement;
          const scrollTop = container?.scrollTop;
          // Apply your custom logic based on the scroll position
          const newSticky = scrollTop > 0 ? true : false; // Example: set isSticky based on scroll position
          if (newSticky !== this.isScrolled) {
            this.isScrolled = newSticky;
            this.cdRef.detectChanges();
          }

          const isScrollNearBottom =
            container.scrollHeight - container.clientHeight <= container.scrollTop + 1;

          if (isPlatformBrowser(this.platform) && isScrollNearBottom) {
            this.sharedService.fireScrollEvent();
          }
        });
    }
  }

  ngOnInit(): void {
    this.router.events.pipe(
      takeUntil(this.onDestroy),
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    )
      .subscribe({
        next: event => {
          if (isPlatformBrowser(this.platform)) {
            if (event?.urlAfterRedirects) {
              this.analyticsService.trackEvent("page_view", {
                page_path: event.urlAfterRedirects,
                page_title: document.title,
                page_location: window.location.href
              });
              this.analyticsService.pushTag({
                event: "page_view",
                page_path: event.urlAfterRedirects,
                page_title: document.title,
                page_location: window.location.href
                // You can add more parameters here if you need them
              });
            }
            if (this.swApp) {
              this.swApp.nativeElement.scrollTop = 0;
            }
          }
        }
      });

    const pageUrl = this.doc.location.href;
    this.title.setTitle("Schoonmaakwoede, dé landelijke schoonmaak specialist voor uw huis");
    this.meta.addTags([
      {
        name: "title",
        content: "Schoonmaakwoede, dé landelijke schoonmaak specialist voor uw huis"
      },
      {
        name: "keywords",
        content: "particulier schoonmaakbedrijf, schoonmaakbedrijf voor particulieren,particuliere schoonmaakdiensten,huis schoonmaken, schoonmaken van huis, huis schoonmaak, grote schoonmaak, verbouw schoonmaak,bouw schoonmaak,verhuis schoonmaak, schoonmaak bij verhuizing, schoonmaak per uur, schoonmaak op maat, schoonmaak specialist, schoonmaak service, schoonmaak service voor particulieren."
      },
      {
        name: "canonical",
        content: pageUrl
      },
      {
        name: "description",
        content: "Uw huis schoon laten maken door professionele vakmensen? Bestel direct online de schoonmaak voor uw huis."
      },
      {
        name: "og:title",
        content: "Schoonmaakwoede, dé landelijke schoonmaak specialist voor uw huis"
      },
      {
        name: "og:description",
        content: "Uw huis schoon laten maken door professionele vakmensen? Bestel direct online de schoonmaak voor uw huis."
      },
      {
        name: "og:url",
        content: pageUrl
      },
      {
        name: "og:image",
        content: "https://schoonmaakwoede.nl/assets/logo.png" // ToDo: add image url
      },
      {
        name: "robots",
        content: "index, follow"
      },
      {
        name: "viewport",
        content: "width=device-width, initial-scale=1.0"
      },
      {
        charset: "UTF-8"
      },
    ]);
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
