import {Component, ElementRef, Inject, PLATFORM_ID, ViewChild} from "@angular/core";
import {FormControl, FormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {ZIP_CODE_REG_EX} from "../../../shared/utils/utils";
import {environment} from "../../../../environments/environment";
import {DOCUMENT, isPlatformBrowser} from "@angular/common";
import {Observable} from "rxjs";
import {GoogleReviewList} from "../../../shared/interfaces/google-review.interface";
import {FirestoreService} from "../../../core/services/firestore.service";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'sw-headline',
  templateUrl: './headline.component.html',
  animations: [
    trigger('fadeIn', [
      state('hidden', style({
        opacity: 0,
        transform: 'translateY(20px)'
      })),
      state('visible', style({
        opacity: 1,
        transform: 'translateY(0)'
      })),
      transition('hidden => visible', [
        animate('1s ease-in-out')
      ])
    ])
  ]
})
export class HeadlineComponent {
  @ViewChild('childInput', {static: false}) childInput?: ElementRef;
  stats$: Observable<Partial<GoogleReviewList>>
  zipCodeForm: FormGroup = new FormGroup({
    zipCode: new FormControl<string>('', [Validators.required, Validators.pattern(ZIP_CODE_REG_EX)])
  })

  isVisible = false;
  isSmallScreen = false;
  constructor(@Inject(DOCUMENT) private document: Document,
              @Inject(PLATFORM_ID) private platformId: Object,
              private breakpointObserver: BreakpointObserver,
              private dataService: FirestoreService) {
    this.stats$ = this.dataService.getStats()
  }


  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.breakpointObserver.observe([
        Breakpoints.Handset,
        '(max-width: 640px)'
      ]).subscribe(result => {
        this.isSmallScreen = result.matches;
        if (this.isSmallScreen) {
          setTimeout(() => {
            this.isVisible = true;
          }, 1000); // 1 second delay
        } else {
          this.isVisible = true;
        }
      });
    }
  }


  onStart(form: FormGroupDirective): void {
    const zipCode = form.value?.zipCode;

    if (isPlatformBrowser(this.platformId) && form.valid) {
      const url = `${environment.redirectUrl}location?packageId=gs&zipCode=${zipCode}`

      this.document.location.href = url

    }
  }

  focusChildInput(): void {
    this.childInput?.nativeElement.focus();
  }
}
