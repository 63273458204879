import {animate, style, transition, trigger} from '@angular/animations';

export const SlideInAnimation = [
    trigger('enterAnimation', [
        transition(':enter', [
            // :enter is alias to 'void => *'
            style({opacity: 0}),
            animate(500, style({opacity: 1})),
        ]),
    ]),]
export const SlideOutAnimation = [
    trigger('leaveAnimation', [
        transition(':leave', [
            // :enter is alias to 'void => *'
            style({opacity: 1}),
            animate(500, style({opacity: 0})),
        ]),
    ]),]
