<!-- Main modal -->
<div class="fixed inset-0 py-12 bg-[#5a5a5a99] flex items-center justify-center z-40">
  <div class="relative w-full max-w-modal max-h-full">
    <!-- Modal content -->
    <div class="relative bg-white rounded-3xl shadow">
      <h3 class="py-4 text-center
                 border-b-2 border-divider
                 text-2xl font-semibold
                 text-darker">
        Zakelijk
      </h3>

      <button type="button"
              (click)="onCloseButton()"
              class="absolute top-3 right-2.5
                     text-gray-400 bg-transparent
                     hover:bg-gray-200 hover:text-gray-900
                     text-sm p-1.5
                     ml-auto inline-flex items-center">
        <svg aria-hidden="true" class="w-8 h-8" fill="currentColor" viewBox="0 0 20 20"
             xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"></path>
        </svg>
        <span class="sr-only">Close modal</span>
      </button>
      <div class="bg-secondary overflow-x-hidden
                  overflow-y-auto h-fit
                  max-h-[75vh] rounded-b-3xl
                  px-6 py-6 lg:px-8">

        <sw-contact-form [isBusiness]="true"></sw-contact-form>

      </div>
    </div>
  </div>
</div>

