import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactFormComponent } from './contact-form.component';
import {SharedModule} from "../../shared.module";



@NgModule({
  declarations: [
    ContactFormComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [
    ContactFormComponent
  ]
})
export class ContactFormModule { }
