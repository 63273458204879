import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {Observable, take} from "rxjs";
import {PageInterface} from "../../interfaces/page.interface";
import {map} from "rxjs/operators";
import {DomSanitizer, Meta, SafeHtml, Title} from "@angular/platform-browser";
import {PageUrlService} from "../../../core/services/pageUrl.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: "sw-cms-page",
  templateUrl: "./cms-page.component.html",
})
export class CmsPageComponent implements OnInit {
  page$?: Observable<PageInterface>;

  constructor(
    private pageUrlService: PageUrlService,
    private route: ActivatedRoute,
    private meta: Meta,
    private title: Title,
    private sanitizer: DomSanitizer) {
  }


  ngOnInit(): void {
    this.page$ = this.route?.data?.pipe(
      take(1),
      map(data => {
      const page = data["cmsContent"] as PageInterface;
      if(!environment.production) {
        console.log(page);
      }
      if (page) {
        const pageUrl = this.pageUrlService.getPageUrl();
        this.title.setTitle(page?.title);
        this.meta.addTags([
          {
            name: "title",
            content: page?.metaTitle
          },
          {
            name: "description",
            content: page?.metaDescription
          },
          {
            name: "keywords",
            content: page?.metaKeywords
          },
          {
            name: "canonical",
            content: pageUrl
          },
          {
            name: "og:title",
            content: page?.ogTitle
          },
          {
            name: "og:description",
            content: page?.ogDescription
          },
          {
            name: "og:url",
            content: pageUrl
          },
          {
            name: "og:image",
            content: page.ogImage
          },
          {
            name: "robots",
            content: "index, follow"
          },
          {
            name: "viewport",
            content: "width=device-width, initial-scale=1.0"
          },
          {
            charset: "UTF-8"
          },
        ]);
      }
      return page;
    }));
  }


  getSafeHtml(content: string): SafeHtml {
      return this.sanitizer.bypassSecurityTrustHtml(content);
  }

}
