import {Component} from '@angular/core';
import {SlideInAnimation, SlideOutAnimation} from "../../../utils/animations";
import {SharedService} from "../../../../core/services/shared.service";

@Component({
  selector: 'sw-business-dialog',
  templateUrl: './business-dialog.component.html',
  animations: [SlideInAnimation, SlideOutAnimation]
})
export class BusinessDialogComponent {

  constructor(private sharedService: SharedService) {
  }

  onCloseButton() {
    this.sharedService.closeModal()
  }
}
