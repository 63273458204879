<section class="w-screen h-screen
                flex flex-col
                overflow-hidden
                bg-secondary relative">
  <div class="sticky top-0 h-0 z-30">
    <sw-header [isScrolled]="isScrolled"></sw-header>
  </div>

  <div #swApp
       cdkScrollable
       class="w-full h-fit z-10
              overflow-x-hidden overflow-y-auto">

    <router-outlet></router-outlet>

    <sw-footer></sw-footer>
  </div>
</section>

<sw-business-dialog *ngIf="showModal$ | async"></sw-business-dialog>
