<div class="w-full h-full mx-auto">
  <div class="w-full h-full
              bg-secondary relative
              flex flex-col
              gap-y-4 justify-start
              shadow
              rounded-[1.563rem] p-6">
    <div class="w-full flex
                gap-2 items-end
                relative pt-20">
      <div class="h-14 md:h-[4.75rem]
                  w-14 md:w-[4.75rem]
                  opacity-30 absolute
                  -left-3 -top-6 md:-top-16
                  text-[20rem] md:text-[25rem]
                  text-primary font-semibold
                  text-center leading-[3.5rem]
                  md:leading-[4.75rem]"
           style="font-family: sans-serif !important;"
           [class.!-left-6]="number === 1">
        {{ number }}
      </div>

      <div class="text-xl text-darker
                  uppercase font-semibold">
        {{ item?.header }}
      </div>
    </div>

    <div class="w-full text-gray">
      {{ item?.text }}
    </div>
  </div>
</div>
