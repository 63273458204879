import {AfterViewInit, Component, ElementRef, OnDestroy, ViewChild} from "@angular/core";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {Subscription} from "rxjs";

@Component({
  selector: "sw-video",
  templateUrl: "./video.component.html",
  styles: [`.sw-video-container {
    position: relative;
    width: 100%;
  }

  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 30px 0 30px 60px;
    border-color: transparent transparent transparent white;
  }

  @media screen and (max-width: 600px) {
    .play-button {
      display: none;
    }
  }

  `]
})
export class VideoComponent implements AfterViewInit, OnDestroy {
  @ViewChild("swVideo") myVideo?: ElementRef;
  videoState: string = "pause"; // initial state
  isHandset: boolean = false;

  private breakpointSubscription: Subscription;

  constructor(private breakpointObserver: BreakpointObserver) {
    // Check if the current device is a handset
    this.breakpointSubscription = this.breakpointObserver.observe([
      Breakpoints.HandsetPortrait,
      Breakpoints.HandsetLandscape
    ]).subscribe(result => {
      this.isHandset = result.matches;
    });
  }

  ngAfterViewInit() {
    if (this.myVideo) {
      this.myVideo.nativeElement.onended = () => {
        this.videoState = "pause";
      };
    }


  }

  toggleVideo() {
    if (this.isHandset || !this.myVideo) {
      return;
    }
    let video = this.myVideo.nativeElement;
    if (video.paused) {
      video.play();
      this.videoState = "play";
    } else {
      video.pause();
      this.videoState = "pause";
    }
  }


  ngOnDestroy() {
    this.breakpointSubscription.unsubscribe();
  }

}
