import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BusinessDialogComponent} from './business-dialog.component';
import {ContactFormModule} from "../../contact-form/contact-form.module";


@NgModule({
  declarations: [
    BusinessDialogComponent
  ],
  imports: [
    CommonModule,
    ContactFormModule
  ],
  exports: [
    BusinessDialogComponent
  ]
})
export class BusinessDialogModule {
}
