<div class="w-full h-fit
            overflow-y-auto overflow-x-hidden">
    <div class="w-full flex flex-col">

        <!-- Headline  -->
        <sw-headline></sw-headline>
        <!-- Headline  -->

        <!--  Body  -->
        <sw-section-one (openLink)="onOpenLink($event)"></sw-section-one>

        <sw-section-two></sw-section-two>

        <sw-section-three></sw-section-three>
        <!--  Body  -->

    </div>
</div>
