import {Pipe, PipeTransform} from "@angular/core";


@Pipe({name: "convertBreadCrumb"})
export class ConvertBreadCrumbPipe implements PipeTransform {
  transform(value: string | undefined): string {
    if (!value) {
      return "";
    }
    // Replace each slash with a slash followed by a space
    const conversion = value.replace(/\//g, " / ");
    return conversion;
  }
}
