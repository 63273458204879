<div class="w-full h-full mx-auto">
    <div class="w-full h-full
                bg-secondary relative
                flex flex-col shadow
                gap-y-4 justify-center
                rounded-[1.563rem] p-6">
        <div class="h-[4.625rem] w-[4.625rem]
                    -top-2 absolute flex
                    justify-center items-center
                    bg-primary rounded-2xl p-1"
             [ngClass]="{ '-right-2': showIconRight,
                          '-left-2': !showIconRight }">
            <img src="assets/icons/{{ item?.icon}}.svg"
                 alt="gs"/>
        </div>

        <div class="text-xl text-darker font-semibold"
             [ngClass]="{ 'mt-16': !showIconRight,
                          'mt-[0.75rem]': showIconRight,
                          '!mt-[3.75rem]': showSmallCard}">
            {{ item?.header }}
        </div>

        <div class="w-full text-gray">
            {{ item?.text }}
        </div>

        <div class="w-full flex
                    flex-wrap justify-between
                    items-center mt-auto">
            <button *ngIf="btn1"
                    type="button"
                    (click)="onBtn1(item)"
                    class="w-fit h-9
                           px-5 py-2 my-2
                           flex items-center
                           bg-primary rounded-full
                           text-lg font-semibold
                           whitespace-nowrap cursor-pointer
                           text-white ripple">
                Bestel nu
            </button>

            <button *ngIf="btn2"
                    type="button"
                    (click)="onBtn2(item)"
                    class="w-fit h-9
                           px-5 py-2 my-2
                           flex items-center
                           bg-white rounded-full
                           text-lg font-semibold
                           text-primary
                           border border-primary
                           whitespace-nowrap cursor-pointer
                           ripple ripple-white">
                Meer info
            </button>
        </div>
    </div>
</div>
