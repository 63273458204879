import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {HeaderComponent} from "./components/header/header.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {HttpClientModule} from "@angular/common/http";
import {FooterComponent} from "./components/footer/footer.component";
import {CdkAccordionModule} from "@angular/cdk/accordion";
import {RatingsComponent} from "./components/ratings/ratings.component";
import {MediaWizardComponent} from "./components/media-wizard/media-wizard.component";
import {VideoComponent} from "./components/video/video.component";
import {CardComponent} from "./components/card/card.component";
import {CleaningInfoComponent} from "./components/cleaning-info/cleaning-info.component";
import {ZipCodeComponent} from "./components/zip-code/zip-code.component";
import {CleaningCardComponent} from "./components/cleaning-info/cleaning-card/cleaning-card.component";
import {CapitalizeFirstCharacterModule} from "./pipes/capitalizeFirstCharacter.module";
import {ConvertIsoModule} from "./pipes/convertIso.module";


const COMMON = [
  FormsModule,
  ReactiveFormsModule,
  RouterLink,
  RouterLinkActive,
  HttpClientModule,
  CdkAccordionModule,
];

const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  MediaWizardComponent,
  VideoComponent,
  RatingsComponent,
  CardComponent,
  CleaningInfoComponent,
  ZipCodeComponent,
  CleaningCardComponent,
];

@NgModule({
  declarations: [
    COMPONENTS,
  ],
    imports: [
        CommonModule,
        COMMON,
        CapitalizeFirstCharacterModule,
        ConvertIsoModule,
    ],
  exports: [
    COMPONENTS,
    COMMON,
    VideoComponent,
    CleaningInfoComponent,
    ZipCodeComponent,
  ]
})
export class SharedModule {
}
