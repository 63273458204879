import {APP_ID, NgModule} from "@angular/core";
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SharedModule} from "./shared/shared.module";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {OverlayModule} from "@angular/cdk/overlay";
import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {environment} from '../environments/environment';
import {getFirestore, provideFirestore} from '@angular/fire/firestore';
import {FIREBASE_OPTIONS} from "@angular/fire/compat";
import {NgOptimizedImage} from "@angular/common";
import {getStorage, provideStorage} from "@angular/fire/storage";
import {HttpClientModule} from "@angular/common/http";
import {BusinessDialogModule} from "./shared/components/dialogs/business-dialog/business-dialog.module";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {CmsPageModule} from "./shared/components/cms-page/cms-page.module";
import {getAnalytics, provideAnalytics} from "@angular/fire/analytics";

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    OverlayModule,
    ScrollingModule,
    NgOptimizedImage,
    AppRoutingModule,
    SharedModule,
    CmsPageModule,
    HttpClientModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    provideAnalytics(() => getAnalytics()),
    BusinessDialogModule
  ],
  providers: [
    {
      provide: APP_ID, useValue: 'serverApp'
    },
    {
      provide: FIREBASE_OPTIONS,
      useValue: environment.firebaseConfig
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
