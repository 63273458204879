<div class="sw-video-container">
  <video #swVideo id="swVideo" playsinline controls preload="auto" width="100%">
    <source
      *ngIf="isHandset"
      src="https://firebasestorage.googleapis.com/v0/b/schoonmaakwoede-productie.appspot.com/o/schoonmaakwoede-small.mp4?alt=media&token=cc22bd14-2550-4b58-8f88-0c5643622535&_gl=1*rke9m*_ga*Mzc1NjY2NDMyLjE2MzYxODE5NDk.*_ga_CW55HF8NVT*MTY5NzgxMTU4Mi4zNzQuMS4xNjk3ODExNTg1LjU3LjAuMA"
      type="video/mp4">
    <source
      *ngIf="!isHandset"
      src="https://firebasestorage.googleapis.com/v0/b/schoonmaakwoede-productie.appspot.com/o/schoonmaakwoede-medium.mp4?alt=media&token=ad8d0da0-30ce-43d1-97ee-039cd5a348ae&_gl=1*1ltgx66*_ga*Mzc1NjY2NDMyLjE2MzYxODE5NDk.*_ga_CW55HF8NVT*MTY5NzgxMTU4Mi4zNzQuMS4xNjk3ODExNzI1LjU4LjAuMA"
      type="video/mp4">
    Your browser does not support the video tag.
  </video>
  <div *ngIf="!isHandset" (click)="toggleVideo()" [style.display]="videoState === 'play' ? 'none' : 'block'"
       class="play-button"></div>
</div>




