<div class="w-full">
  <div class="w-full mx-auto
              flex flex-col p-[0.938rem]
              gap-2 md:text-center">
    <h2 class="w-full text-2xl
                leading-[2.25rem]
                md:text-[2.375rem]
                md:leading-[3.563rem]
                text-darker font-semibold">
      Schoonmaakwoede in de media
    </h2>

    <div class="h-1 w-32
                bg-divider md:mx-auto
                my-[0.9rem] md:my-[1rem]"></div>

  </div>

  <div class="md:hidden h-[300px]">
    <section class="carousel !p-0">
      <ol class="carousel__viewport !h-[275px]">
        <li *ngFor="let item of items; let i = index"
            id="carousel__slide{{i}}"
            class="carousel__slide mx-2.5">
          <div class="carousel__snapper">
            <div class="w-full relative p-[1.125rem]">
              <div class="w-full max-w-[20.313rem]
                          h-full min-h-[13.438rem]
                          flex mx-auto
                          bg-secondary shadow-md
                          shadow-light-gray
                          flex-col gap-2
                          rounded-[1.563rem] p-6">
                <div class="w-full rounded-[2.25rem] p-[0.938rem]">
                  <img class="h-[5.375rem] mx-auto"
                       src="assets/icons/{{ item?.icon }}.svg"
                       alt="hp"/>
                </div>

                <div class="w-full text-center text-secondary-gray">
                  {{ item?.header }}
                </div>
              </div>
            </div>
          </div>
        </li>
      </ol>
    </section>
  </div>

  <div class="w-full hidden
              max-w-container mx-auto
              p-[0.938rem] mt-[2.75rem]
              md:flex flex-col
              sm:flex-row sm:flex-wrap
              justify-around">
    <div *ngFor="let item of items; let i  = index"
         class="w-full sm:w-1/2
                first:pl-0 last:pr-0
                xl:w-1/4 p-4 -mx-4">
      <div class="w-full max-w-[20.313rem]
                  h-full min-h-[13.438rem]
                  bg-secondary flex
                  shadow-md shadow-light-gray
                  flex-col gap-2
                  rounded-[2.25rem] p-6"
           [class.ml-auto]="i === 0 || i === 2"
           [class.mr-auto]="i === 1 || i === 3"
      >
        <div class="w-full rounded-[2.25rem] p-[0.938rem]">
          <img class="mx-auto h-[5.375rem]"
               src="assets/icons/{{ item?.icon }}.svg"
               alt="hp"/>
        </div>

        <div class="w-full text-center text-secondary-gray">
          {{ item?.header }}
        </div>
      </div>
    </div>
  </div>
</div>
