import {Injectable, Inject, PLATFORM_ID, Optional} from "@angular/core";
import { isPlatformServer } from '@angular/common';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class PageUrlService {
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    @Optional() @Inject(REQUEST) private request: Request // Use @O
  ) {}

  getPageUrl(): string {
    if (isPlatformServer(this.platformId)) {
      // Server-side logic
      return this.request.protocol + '://' + this.request.get('host') + this.request.originalUrl;
    } else {
      // Browser-side logic
      return this.document.location.href;
    }
  }
}
