import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ConvertBreadCrumbPipe} from "./convertBreadCrumb.pipe";

@NgModule({
    declarations: [
      ConvertBreadCrumbPipe
    ],
    imports: [
        CommonModule,
    ],
    exports: [
      ConvertBreadCrumbPipe
    ]
})
export class ConvertBreadCrumbModule {
}
