import {Component} from '@angular/core';
import {Observable, take} from "rxjs";
import {FirestoreService} from "../../../core/services/firestore.service";
import {GoogleReview} from "../../interfaces/google-review.interface";

@Component({
  selector: 'sw-ratings',
  templateUrl: './ratings.component.html',
})
export class RatingsComponent {
  ratings$: Observable<GoogleReview[]>

  constructor(private dataService: FirestoreService) {
    this.ratings$ = this.dataService.getLatestReviews().pipe(take(1))
  }
}

