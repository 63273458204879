import {Component, Inject, Input, PLATFORM_ID} from "@angular/core";
import {NavigationInterface} from "../../interfaces/navigation.interface";
import {SlideInAnimation, SlideOutAnimation} from "../../utils/animations";
import {environment} from "../../../../environments/environment";
import {DOCUMENT, isPlatformBrowser, isPlatformServer} from "@angular/common";
import {PackageInterface} from "../../interfaces/package.interface";
import {Router} from "@angular/router";

@Component({
  selector: 'sw-header',
  templateUrl: './header.component.html',
  animations: [SlideInAnimation, SlideOutAnimation],
})
export class HeaderComponent {
  @Input() isScrolled: boolean = false;

  pages: NavigationInterface[] = [{
    label: 'home',
    routeLink: '/',
  }, {
    label: 'alle diensten',
    routeLink: '/schoonmaakdiensten',
  }, {
    label: 'referenties',
    routeLink: '/referenties',
  }, {
    label: 'faq',
    routeLink: '/klantenservice',
  }, {
    label: 'zakelijk',
    routeLink: '/zakelijk',
  }, {
    label: 'contact',
    routeLink: '/contact',
  }
  ]

  showHeader: boolean = false;
  redirectUrl: string = `${environment.redirectUrl}login`;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
              @Inject(DOCUMENT) private document: Document,
              private router: Router) {
  }

  switchLocale() {
    const localeRedirectUrl = environment.localeRedirectUrl;
    if (isPlatformBrowser(this.platformId)) {
      window.location.href = localeRedirectUrl;
    }
  }


  onBtn1(){
    const result = this.router.url.substring(this.router.url.indexOf("schoonmaakdiensten/")) as string;
    const packageName = result?.replace("schoonmaakdiensten/", "") as string;
    const packageId: PackageInterface["packageId"] | null = this.getPackageId(packageName);
    if (isPlatformServer(this.platformId)) {
      return;
    }

    if (packageId) {
      if (packageId === "es") {
        const url = `${environment.redirectUrl}es/location?packageId=${packageId}`;
        this.document.location.href = url;
      } else {
        const url = `${environment.redirectUrl}location?packageId=${packageId}`;
        this.document.location.href = url;
      }
    } else  {
     this.router.navigate(['/schoonmaakdiensten'])
    }
  }


  private getPackageId(packageName: string): PackageInterface["packageId"] | null {
    if (packageName === "eind-schoonmaak")
      return "es";
    else if (packageName === "grote-schoonmaak")
      return "gs";
    else if (packageName === "zwangerschap-schoonmaak")
      return "zs";
    else if (packageName === "voorjaar-schoonmaak")
      return "ls";
    else if (packageName === "verhuis-schoonmaak")
      return "vs";
    else if (packageName === "verbouw-schoonmaak")
      return "rs";
    else if (packageName === "schoonmaak-per-uur")
      return "ms";
    else return null;
  }
}
