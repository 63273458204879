<div class="w-full flex flex-col min-h-[500px]">
  <div class="md:hidden mb-[4rem] relative">
    <section class="carousel -mb-24">
      <ol class="carousel__viewport min-h-[505px] pt-[100px]">
        <li *ngFor="let item of items; let i = index"
            id="carousel__slide{{i}}"
            class="carousel__slide mx-2.5">
          <div class="carousel__snapper">
            <div class="w-full max-w-card mx-auto">
              <div class="mx-auto w-full max-w-[27.625rem]">
                <sw-cleaning-card [item]="item" [number]="i + 1"></sw-cleaning-card>
              </div>
            </div>
          </div>
        </li>
      </ol>
    </section>
  </div>

  <div class="hidden md:flex mx-auto
                w-full max-w-container
                gap-x-[1.125rem] gap-y-8
                flex-wrap justify-around">
    <div *ngFor="let item of items; let i = index"
         class="w-full min-w-[22.5rem] max-w-[27.625rem]">
      <sw-cleaning-card [item]="item" [number]="i + 1"></sw-cleaning-card>
    </div>
  </div>
</div>
