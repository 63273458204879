import {Component, EventEmitter, Input, Output, ViewEncapsulation} from "@angular/core";
import {SectionItemsInterface} from "../../interfaces/section-items.interface";

@Component({
  selector: "sw-card",
  templateUrl: "./card.component.html",
  encapsulation: ViewEncapsulation.None

})
export class CardComponent {
  @Input() item?: SectionItemsInterface;
  @Input() btn1?: boolean;
  @Input() btn2?: boolean;
  @Input() showIconRight?: boolean;
  @Input() showSmallCard?: boolean;
  @Output() btn1Click: EventEmitter<SectionItemsInterface> = new EventEmitter<SectionItemsInterface>();
  @Output() btn2Click: EventEmitter<SectionItemsInterface> = new EventEmitter<SectionItemsInterface>();

  onBtn1(item: SectionItemsInterface | undefined): void {
    if(item) {
      this.btn1Click.emit(item);
    }
  }

  onBtn2(item: SectionItemsInterface | undefined): void {
    if (item) {
      this.btn2Click.emit(item);
    }
  }
}
