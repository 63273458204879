import {Component} from '@angular/core';
import {SectionItemsInterface} from "../../interfaces/section-items.interface";

@Component({
    selector: 'sw-media-wizard',
    templateUrl: './media-wizard.component.html',

})
export class MediaWizardComponent {
    selectedCarousel: number = 1;
    items: SectionItemsInterface[] = [
        {
            icon: 'het-parool',
            header: 'Schoon op Bestelling',
            text: ''
        },
        {
            icon: 'fd',
            header: 'Stoffen en poetsen',
            text: ''
        },
        {
            icon: 'rtl-4',
            header: 'Woontips',
            text: ''
        },
        {
            icon: 'volkskrant',
            header: 'Lang leve de voorjaarsbeurt',
            text: ''
        }
    ];
}
