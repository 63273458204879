import {Component, Input, OnInit} from "@angular/core";
import {AbstractControl, FormControl, FormGroup, FormGroupDirective, Validators} from "@angular/forms";
import {FirestoreService} from "../../../core/services/firestore.service";
import {first} from "rxjs";

@Component({
  selector: "sw-contact-form",
  templateUrl: "./contact-form.component.html",

})
export class ContactFormComponent implements OnInit {
  @Input() isBusiness: boolean | undefined;
  contactForm: FormGroup = new FormGroup({});
  success: boolean | undefined;
  error: boolean | undefined;
  loading: boolean;

  constructor(private firebaseService: FirestoreService) {
    this.loading = false;
  }

  ngOnInit(): void {
    this.addContactFormControls(this.isBusiness);
  }

  addContactFormControls(isBusiness: boolean | undefined): void {
    const PHONE_REG_EX = new RegExp(`^(?:(?:\\+|00)?\\d{1,3})?(?:\\d{9}|\\d{10})$`)
    if (isBusiness) {
      this.contactForm = new FormGroup({
        firstName: new FormControl<string>("", [Validators.required]),
        lastName: new FormControl<string>("", [Validators.required]),
        email: new FormControl<string>("", [Validators.required, Validators.email]),
        phoneNumber: new FormControl<string>("", [Validators.required, Validators.pattern(PHONE_REG_EX)]),
        business: new FormControl<string>("", [Validators.required]),
        remark: new FormControl<string>("", [Validators.required]),
      });
    } else {
      this.contactForm = new FormGroup({
        firstName: new FormControl<string>("", [Validators.required]),
        lastName: new FormControl<string>("", [Validators.required]),
        email: new FormControl<string>("", [Validators.required, Validators.email]),
        phoneNumber: new FormControl<string>("", [Validators.required, Validators.pattern(PHONE_REG_EX)]),
        remark: new FormControl<string>("", [Validators.required]),
      });
    }
  }

  onSubmit(form: FormGroupDirective): void {
    this.success = false;
    this.error = false;
    if (form.valid && !this.loading) {
      this.loading = true;

      const body = {
        ...form.value,
      };

      this.firebaseService.sendMail(body)
        .pipe(first())
        .subscribe({
          next: () => {
            this.success = true;
            this.loading = false;
          },
          error: (error: Error) => {
            this.error = true;
            this.loading = false;
            console.error("Error: ", error);
          }
        });
    }
  }

  getFormControl(name: string): AbstractControl | null {
    return this.contactForm.get(`${name}`);
  }
}

