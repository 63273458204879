import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';

import {HomeRoutingModule} from './home-routing.module';
import {HomeComponent} from './home.component';
import {HeadlineComponent} from './headline/headline.component';
import {SectionOneComponent} from './section-one/section-one.component';
import {SectionTwoComponent} from './section-two/section-two.component';
import {SectionThreeComponent} from './section-three/section-three.component';
import {SharedModule} from "../../shared/shared.module";


@NgModule({
    declarations: [
        HomeComponent,
        HeadlineComponent,
        SectionOneComponent,
        SectionTwoComponent,
        SectionThreeComponent,
    ],
    exports: [
        SectionThreeComponent
    ],
    imports: [
        NgOptimizedImage,
        CommonModule,
        HomeRoutingModule,
        SharedModule
    ]
})
export class HomeModule {
}
