import { Pipe, PipeTransform } from '@angular/core';
import {DateTime, Settings} from "luxon";

Settings.defaultZone = "Europe/Amsterdam"; // Amsterdam Timezone
Settings.defaultLocale = "nl"; // Dutch locale

@Pipe({ name: 'convertIso' })
export class ConvertISOstringPipe implements PipeTransform {
    transform(value: string | undefined): string {
        if (!value) {
            return '';
        }
        const date = DateTime.fromISO(value)
        return date.toFormat('dd-MM-yy')
    }
}
